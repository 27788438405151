<template>
  <div class="container">
     <search-item @input="searchlist" :keyword="keyword" ></search-item>
    <div class="top">
    <div class="type">
          <div class="left">服务类别：</div>
          <div class="right">
            <div class="item " v-for="(item,i) in type1" :key="item.id" :class="{'on':i==current1}" @click="choosetype1(i)">{{item.name}}</div>
          </div>
        </div>
        <div class="type">
          <div class="left">服务分类：</div>
          <div class="right">
            <div class="item " v-for="(item,i) in type2" :key="item.id" :class="{'on':i==current2}"  @click="choosetype2(i)">{{item.name}}</div>
          </div>
        </div>
    </div>
   
    <div class="flex fwtj">
      <div class="item" v-for="(item, i) in list" :key="i"  >
        <div class="img" @click="openpage('/fwdetail',item.id)">
          <img :src="item.thumb" alt="暂无图片" v-if="item.thumb" />
        </div>
        <div class="btn2">
          <div class="name" @click="openpage('/fwdetail',item.id)">{{ item.title }}</div>
          <div class="intro" @click="openpage('/fwdetail',item.id)">{{ item.description }}</div>
          <div class="flex zixun">
            <div class="btn btn-warning-sm"  @click="getkefu()">咨询管家</div>
            <div class="btn btn-primary-sm" @click="openpage('/fbxq',item.id)">发布需求</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" v-show="total>0">
        <el-pagination
        v-if="bPageShow"
    background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="8"
        layout="total, prev, pager, next"
        :total="total">
    </el-pagination>
      </div>
       <el-empty :image-size="150" v-show="total==0"></el-empty>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import searchItem from "@c/common/searchitem.vue";
export default {
  components: { searchItem},
  data() {
    return {
      current1:-1,
      current2:-1,
      type1:[],
      type2:[],
      list: [],
       currentPage:1,
       total:0,
       keyword:"",
       bPageShow: true
    };
  },
  computed: {
     ...mapState(["isLogin", "userInfo"]),
  },
  mounted(){
    
      this.$http.listbyweb().then(res=>{
         this.type1=res.data;
         if(this.$route.params.hasOwnProperty("from")){
           this.keyword=this.$route.params.key;
          //  console.log(this.keyword)
            this.choosetype1(0,this.keyword);
        }else{
            this.choosetype1(0);
        }
        
     })
  },
  methods: {
      ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
     getkefu() {
       if (this.isLogin != 1) {
           this.setLoginDialog(true);
      
        }else{
          this.$store.dispatch("setkefu");
        }
  
    },
    searchlist(val){
      this.keyword=val;
      this.handleCurrentChange(this.currentPage); 
    },
     handleCurrentChange(val) {

    // console.log(val); 
        this.$http.getproductlist({
          cate_id:this.current2==-1?this.type1[this.current1].id:this.type2[this.current2].id,
          offset:(val-1)*8,
          limit:8,
          keyword:this.keyword
        }).then(res=>{
          this.list=res.data.list;
          this.total=res.data.count;
        //  console.log(this.total)
          if(this.total==0){
             this.$message.info({ message: "暂无数据!" });
          }
        })
      },
    choosetype1(i,keyword=""){
         this.keyword=keyword;
        this.current1=i;
        this.type2=this.type1[i].children;
        this.current2=-1;
        this.currentPage=1;
        this.bPageShow = false;
        this.handleCurrentChange(this.currentPage);
        this.$nextTick(()=>{
           this.bPageShow = true;
         });
    },
    choosetype2(i){
       this.keyword="";
      this.current2=i;
      this.currentPage=1;
      this.bPageShow = false;
      this.handleCurrentChange(this.currentPage);
      this.$nextTick(()=>{
           this.bPageShow = true;
      });
    },
   
     openpage(path,id, islogin){
        if (islogin) {
          if (this.isLogin != 1) {
            this.setLoginDialog(true);
            // this.$message.error("请先登录");
            return;
          }
      }
        this.$router.push({
            path,
            query: {
              id,
            },
        })
     }
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
}
.pagination{
  // background: #fff;
  text-align: center;
  padding: 20px ;
}
.top{
  background: #fff;
  padding: 20px 20px ;
  border-radius: 5px;
  margin-bottom: 20px;
}
.type{
  
  display: flex;
 
  font-size: 18px;
  &:first-child{
     margin-bottom: 25px;
  }
  .left{
    width: 110px;
    font-weight: bold;
    @include flexcenter;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
  }
  .right{
     flex: 1;
     display: flex;
      align-items: center;
      flex-wrap: wrap;
    .item{
        padding:5px 18px ;
        margin-right: 10px;
       cursor: pointer;
       min-width:110px ;
       text-align: center;
        &.on{
          color: #fff;
          border-radius: 20px;
          background: $color-theme;
        }
    }
  }

}
.fwtj {
  width: 100%;
  flex-wrap: wrap;
  //  padding: 0 20px;
//   justify-content: space-evenly;
  .item {
    width: calc(25% - 15px);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
     cursor: pointer;
    &:nth-child(4n+4){
        margin-right: 0;
    }
    .img img{
      width: 100%;
      height: 200px;
      object-fit: cover;
       display: block;
    }
  }

  .btn2 {
    padding:10px 20px ;
    font-size: 20px;
    margin-bottom: 10px;
    .name {
        font-size: 20px;
        margin-bottom: 6px;
        @include single-line-ellipsis;
    }
    .intro{
        font-size: 14px;
        color: #666;
        min-height: 15px;
        @include  multi-line-ellipsis(2)
    }
    .zixun {
        margin-top: 10px;
      justify-content: space-between;
    }
  }
}
</style>
